
import classnames from 'classnames';
import GatewayOnlineOffline from 'common/components/GatewayOnlineOffline';
import { PicklistOption } from 'common/components/Picklist';
import I18n from 'common/i18n';
import { formatDsmapiDateWithLocale, PhxSocket } from 'common/types/dsmapi';
import { Agent, Plugin } from 'common/types/gateway';
import withModal, { AgentModalType, WithModalProps } from 'dataGateway/hocs/withModal';
import { AppState } from 'dataGateway/store';
import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { option, Option } from 'ts-option';
import AgentEvents from './AgentEvents';
import AgentLogs from './AgentLogs';
import Connecting from './Connecting';
import NamespacesTable from './NamespacesTable';
import ProvisionPluginButton from './ProvisionPluginButton';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'data_gateway', ...options });

enum Tabs {
  Plugins = 'plugins',
  Logs = 'logs',
  Events = 'events'
}

const UptimeDowntime = ({ agent }: { agent: Agent }) => {
  let text = '';
  if (agent.went_offline_at) {
    const offlineAt = t('downtime', { time: formatDsmapiDateWithLocale(agent.went_offline_at) });
    if (agent.offline_reason) {
      const reason = t(`downtime_reasons.${agent.offline_reason.key}`, agent.offline_reason.params) || agent.offline_reason.english;
      text = `${offlineAt}, ${reason}`;
    } else {
      text = offlineAt;
    }
  } else if (agent.went_online_at) {
    text = t('uptime', { time: formatDsmapiDateWithLocale(agent.went_online_at) });
  }

  return <p className="agent-uptime-downtime">{text}</p>;
};

const SidebarLink = ({ agent, tab, selectedTab }: { agent: Agent, tab: Tabs, selectedTab: Tabs }) => {
  const klasses = classnames({
    'sidebar-tab': true,
    'selected': tab === selectedTab
  });

  const onClick = () => {
    browserHistory.push(`/admin/gateway/${agent.agent_uid}/${tab}`);
  };
  return (
    <a key={tab} className={klasses} onClick={onClick}>{t(tab)}</a>
  );
};


const stringToTab = (s: any): Tabs | undefined => {
  if (s === Tabs.Logs) return Tabs.Logs;
  if (s === Tabs.Plugins) return Tabs.Plugins;
  if (s === Tabs.Events) return Tabs.Events;
};

export class AgentDashboard extends React.Component<Props> {
  onTab = (op: PicklistOption) => {
    const tab = stringToTab(op.value);
    tab && this.props.agent.forEach(agent => {
      browserHistory.push(`/admin/gateway/${agent.agent_uid}/${tab}`);
    });
  };

  showProvisionPluginModal = () => {
    this.props.showModal({ type: AgentModalType.AvailablePluginsModalT });
  };


  render() {
    const { connected, tab } = this.props;
    if (!connected) {
      return <Connecting />;
    }
    if (this.props.agent.isEmpty) {
      return <div>{t('not_found')}</div>;
    }
    const agent = this.props.agent.get;

    let content;
    if (tab === Tabs.Plugins) content = <NamespacesTable agent={agent} />;
    if (tab === Tabs.Logs) content = <AgentLogs agent={agent} socket={this.props.socket} />;
    if (tab === Tabs.Events) content = <AgentEvents agent={agent} />;

    return (
      <div className="agents-table-dashboard">
        <div className="admin-dashboard cf">
          <div>
            <h1>{agent.name}</h1>
            <p className="agent-uid">{t('agent_id', agent)}</p>
            <UptimeDowntime agent={agent} />

          </div>
          <div className="dash-group">
            <GatewayOnlineOffline agent={agent} />
            <ProvisionPluginButton agents={[agent]} onClick={this.showProvisionPluginModal} />
          </div>
        </div>
        <div className="agents-dashboard-inner">
          <div className="agents-dashboard-selector">
          <section className="dashboard-sidebar sidebar">
            <SidebarLink agent={agent} tab={Tabs.Plugins} selectedTab={this.props.tab} />
            <SidebarLink agent={agent} tab={Tabs.Logs} selectedTab={this.props.tab} />
            <SidebarLink agent={agent} tab={Tabs.Events} selectedTab={this.props.tab} />
          </section>
          </div>
          <div className="agents-dashboard-body">
            {content}
          </div>
        </div>
      </div>
    );
  }
}

interface ExternalProps {
  uid: string;
  tab: string | undefined;
  socket: PhxSocket;
}
interface StateProps {
  tab: Tabs;
  agent: Option<Agent>;
  agents: Agent[];
  plugins: Plugin[];
  connected: boolean;
  socket: PhxSocket;
}

type Props = StateProps & WithModalProps;


const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    agent: option(find(state.agents, a => a.agent_uid === props.uid)),
    plugins: state.plugins,
    agents: state.agents,
    tab: stringToTab(props.tab) || Tabs.Plugins,
    connected: state.connected,
    socket: props.socket
  };
};
export default connect(mapStateToProps)(withModal(AgentDashboard, ['fullsize-modal']));
