import { fetchTranslation } from 'common/locale';
import React from 'react';

const scope = 'data_gateway';
const t = (k: string) => fetchTranslation(k, scope );

const Connecting = () => (
  <div className="agents-table-dashboard-loading">
    <div className="alert agents-connecting">
      <span>{t('connecting')}</span>
    </div>
  </div>
);

export default Connecting;
