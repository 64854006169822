import { DsmapiResource } from 'common/types/dsmapi';
import { Agent, Plugin } from 'common/types/gateway';
import { checkStatus, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'common/dsmapiLinks';
type NoIdea = any;

const getPlugins = (): Promise<Plugin[]> => (
  socrataFetch(dsmapiLinks.pluginTypeIndex)
    .then(checkStatus)
    .then(getJson)
);

const getAgents = (): Promise<DsmapiResource<Agent>[]> => (
  socrataFetch(dsmapiLinks.agentIndex)
    .then(checkStatus)
    .then(getJson)
);

const deleteAgent = (agent: Agent): Promise<DsmapiResource<Agent>> => (
  socrataFetch(dsmapiLinks.showAgent(agent.agent_uid), {
    method: 'DELETE'
  })
    .then(checkStatus)
    .then(getJson)
);

const chownAgent = (agent: Agent, toUserId: string): Promise<DsmapiResource<Agent>> => {
  return socrataFetch(dsmapiLinks.chownAgent(agent, toUserId), {
    method: 'PATCH'
  }).then(checkStatus)
    .then(getJson);
};

const queryUsers = (q: string) => {
  if (!q) {
    return Promise.resolve([]);
  }

  return socrataFetch(`/api/catalog/v1/users/autocomplete?only=site_members&disabled=false&domain=${window.location.hostname}&q=${q}`)
    .then(checkStatus)
    .then(getJson)
    .then((resp: NoIdea) => resp.results);
};



export {
  getPlugins, getAgents, deleteAgent, chownAgent, queryUsers
};
