import * as Dsmapi from 'common/types/dsmapi';
import { DsmapiResource } from 'common/types/dsmapi';
import { Agent } from 'common/types/gateway';
import * as _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Route, Router, IndexRoute } from 'react-router';
import { getPlugins } from './api';
import AgentDashboard from './components/AgentDashboard';
import AgentsTable from './components/AgentsTable';
import init, { ActionTypes } from './store';


export interface RouterProps {
  params: {
    [uid: string]: string;
  };
}

const WrapDashboardForReactRouter = (props: RouterProps) => (
  <AgentDashboard uid={props.params.uid} tab={props.params.tab} socket={socket} />
);

const store = init();

const socket = Dsmapi.socket();
const chan = socket.channel(`agents:${_.get(window, 'socrata.domain_id')}`);
chan.on('update', (changed: Agent) => {
  store.dispatch({ type: ActionTypes.AgentChange, changed });
});
chan.on('insert', (added: Agent) => {
  store.dispatch({ type: ActionTypes.AgentAdded, added });
});
chan.on('agents', ({ resource }: DsmapiResource<Agent[]>) => {
  store.dispatch({ type: ActionTypes.AgentList, agents: resource });
  store.dispatch({ type: ActionTypes.SocketConnected });
});
chan.join();

getPlugins().then(plugins => {
  store.dispatch({ type: ActionTypes.PluginList, plugins });
}).catch(console.error);


ReactDOM.render(
  <Provider store={store}>
    <Router  history={browserHistory}>
      <Route path="/(:locale/)admin/gateway">
        <IndexRoute component={AgentsTable} />
        <Route path=":uid" component={WrapDashboardForReactRouter} />
        <Route path=":uid/:tab" component={WrapDashboardForReactRouter} />
      </Route>
    </Router>
  </Provider>,
  document.querySelector('#data-gateway-main')
);
