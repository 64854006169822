import type { Agent } from 'common/types/gateway';
import type InteractiveUser from 'common/types/users/interactiveUser';
import UserFlags from 'common/types/users/flags';
import DomainRights from 'common/types/domainRights';

// These helpers mirror https://github.com/socrata/dsmapi/blob/main/web/plugs/gateway_auth.ex

function isSuperAdmin(user: InteractiveUser): boolean {
  return (user.flags || []).includes(UserFlags.SuperAdmin);
}

function isAgentOwner(user: InteractiveUser, agent: Agent): boolean {
  return user.id === agent.owned_by.user_id;
}

export function canUpdateAgent(user: InteractiveUser, agent: Agent): boolean {
  return (
    (user.rights || []).includes(DomainRights.manage_gateways) ||
    isSuperAdmin(user) ||
    isAgentOwner(user, agent)
  );
}
