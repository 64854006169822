import Button, { VARIANTS } from 'common/components/Button';
import { currentUserHasRight, getCurrentUser } from 'common/current_user';
import { fetchTranslation } from 'common/locale';
import * as Rights from 'common/rights';
import { Agent } from 'common/types/gateway';
import _ from 'lodash';
import React from 'react';

const t = (k: string) => fetchTranslation(k, 'data_gateway');
interface ProvisionPluginButtonProps {
  agents: Agent[];
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function userOwnsAnAgent(agents: Agent[]) {
  const currentUserId = _.get(getCurrentUser(), 'id', '');
  return agents.some(a => a.owned_by.user_id.toString() === currentUserId);
}

export default function ProvisionPluginButton({ agents, onClick }: ProvisionPluginButtonProps) {
  if (currentUserHasRight(Rights.MANAGE_GATEWAYS) || userOwnsAnAgent(agents)) {
    return (
      <div>
        <Button
          onClick={onClick}
          id="provision-plugin-btn"
          inverse={true}
          variant={VARIANTS.ALTERNATE_2}
          className="dash-button">
          {t('provision_plugin')}
        </Button>
      </div>
    );
  } else {
    return null;
  }
}
