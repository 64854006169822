import Button, { VARIANTS } from 'common/components/Button';
import { currentUserHasRight } from 'common/current_user';
import { fetchTranslation } from 'common/locale';
import * as Rights from 'common/rights';
import { Agent, Plugin } from 'common/types/gateway';
import withModal, { AgentModalType, WithModalProps } from 'dataGateway/hocs/withModal';
import { AppState } from 'dataGateway/store';
import React from 'react';
import { connect } from 'react-redux';
import ProvisionPluginButton from './ProvisionPluginButton';

const t = (k: string) => fetchTranslation(k, 'data_gateway');

interface ProvisionAgentButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function ProvisionAgentButton({ onClick }: ProvisionAgentButtonProps) {
  if (currentUserHasRight(Rights.MANAGE_GATEWAYS) || currentUserHasRight(Rights.CREATE_GATEWAY_AGENTS)) {
    return (
      <div>
        <Button
          onClick={onClick}
          id="provision-agent-btn"
          inverse={true}
          variant={VARIANTS.ALTERNATE_2}
          className="dash-button">
          {t('provision_agent')}
        </Button>
      </div>
    );
  } else {
    return null;
  }
}

interface DashboardProps {
  agents: Agent[];
  plugins: Plugin[];
}

export class Dashboard extends React.Component<DashboardProps & WithModalProps> {
  showProvisionAgentModal = () => this.props.showModal({ type: AgentModalType.ProvisionAgentModalT });
  showProvisionPluginModal = () => this.props.showModal({ type: AgentModalType.AvailablePluginsModalT });

  render() {
    const { agents } = this.props;
    const offlineAgents = agents.filter(a => a.went_offline_at);
    const onlineAgents = agents.filter(a => a.went_online_at);

    return (
      <div className="admin-dashboard cf">
        <div></div>
        <div className="dash-group">
          <div className="dash-statistic">
            <p className="dash-statistic-count dash-statistic-success">{onlineAgents.length}</p>
            <p className="dash-statistic-label">{t('connected')}</p>
          </div>
          <div className="dash-statistic">
            <p className="dash-statistic-count dash-statistic-error">{offlineAgents.length}</p>
            <p className="dash-statistic-label">{t('offline')}</p>
          </div>
          <ProvisionAgentButton onClick={this.showProvisionAgentModal} />
          <ProvisionPluginButton agents={agents} onClick={this.showProvisionPluginModal} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): DashboardProps => {
  return {
    plugins: state.plugins,
    agents: state.agents,
  };
};

export default connect(mapStateToProps)(withModal<DashboardProps>(Dashboard));
