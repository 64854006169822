import { connect } from 'react-redux';
import * as React from 'react';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Checkbox from 'common/components/Checkbox';
import { fetchTranslation } from 'common/locale';
import { Agent, Plugin } from 'common/types/gateway';
import { ActionTypes, AppState, Dispatcher } from 'dataGateway/store';
import * as Api from '../api';
import { DsmapiResource } from 'common/types/dsmapi';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';

const t = (k: string) => fetchTranslation(k, 'data_gateway.delete_agent_modal');


interface State {
  pluginsDeletionConfirmation: boolean;
  schedulesDeletionConfirmation: boolean;
}

export class DeleteAgentModal extends React.Component<Props, State> {
  state = {
    pluginsDeletionConfirmation: false,
    schedulesDeletionConfirmation: false
  };

  handlePluginsDeletionConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pluginsDeletionConfirmation: e.target.checked});
  };

  handleSchedulesDeletionConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ schedulesDeletionConfirmation: e.target.checked});
  };

  handleDelete = (): void => {
    const { agent, deleteAgent, hideModal } = this.props;
    deleteAgent(agent).then(() => {
      hideModal();
      showSuccessToastNow(t('delete_success'));
    }).catch(() => {
      showErrorToastNow(t('delete_error'));
    });
  };

  render() {
    const { hideModal } = this.props;
    const { pluginsDeletionConfirmation, schedulesDeletionConfirmation } = this.state;
    const disabled = !pluginsDeletionConfirmation || !schedulesDeletionConfirmation;
    return (
      <Modal className="delete-agent-modal" onDismiss={hideModal}>
        <ModalHeader title={t('delete_agent')} showCloseButton={false} onDismiss={hideModal} />
        <ModalContent>
          <span className="warning-line">
            <span className="emphasis">{t('warning') + ' '}</span>
            {t('warning_text')}
          </span>
          <form className="delete-agent-confirmations-form">
            <span>{t('instructions')}</span>
            <Checkbox
              checked={pluginsDeletionConfirmation}
              id="pluginsDeletionConfirmation"
              onChange={this.handlePluginsDeletionConfirmation}
              label={t('plugins_deletion_confirmation')}>
              {t('plugins_deletion_confirmation')}
            </Checkbox>
            <Checkbox
              checked={schedulesDeletionConfirmation}
              id="schedulesDeletionConfirmation"
              onChange={this.handleSchedulesDeletionConfirmation}
              label={t('schedules_deletion_confirmation')}>
              {t('schedules_deletion_confirmation')}
            </Checkbox>

          </form>
          <span>{t('datasets_remain')}</span>
        </ModalContent>
        <ModalFooter>
          <button onClick={hideModal} className="btn btn-default">
            {t('cancel')}
          </button>
          <button
            onClick={this.handleDelete}
            className="btn btn-error"
            disabled={disabled}>
            {t('delete_agent')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
interface ExternalProps {
  agent: Agent;
  hideModal: () => void;
}
interface DispatchProps {
  deleteAgent: (agent: Agent) => Promise<DsmapiResource<Agent>>
}
type StateProps = ExternalProps;


type Props = DispatchProps & StateProps;

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return props;
};
const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    deleteAgent: (agent: Agent) => {
      return Api.deleteAgent(agent).then(a => {
        dispatch({ type: ActionTypes.AgentDeleted, deleted: agent });
        return a;
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAgentModal);
