import * as React from 'react';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import UserDetails from 'common/components/AccessManager/components/UserDetails';
import UserSearch from 'common/components/UserSearch';
import { fetchTranslation } from 'common/locale';
import { connect } from 'react-redux';

import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { Agent, AgentOwner } from 'common/types/gateway';
import { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { ViewUser } from 'common/types/view';
import * as Api from '../api';
import { DsmapiResource } from 'common/types/dsmapi';
import { AppState, ActionTypes, Dispatcher } from 'dataGateway/store';

const t = (k: string) => fetchTranslation(k, 'data_gateway.chown_modal');

interface State {
  results: UsersCatalogSearchResults[];
  query: string;
  owner: AgentOwner;
  newOwner: CatalogUserOrTeam[];
}



function toUserDetailsUser(o: AgentOwner): ViewUser {
  return {
    email: o.email,
    displayName: o.display_name,
    id: o.user_id,
    type: 'interactive',
    accessLevels: []
  };
}

function userIsOwner(owner: AgentOwner, record: UsersCatalogSearchResults) {
  const { user, team } = record;
  return user?.id === owner.user_id || team?.id === owner.user_id;
}

class ChownModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      results: [],
      owner: props.agent.owned_by,
      newOwner: [],
      query: ''
    };
  }

  componentDidUpdate(prevProps: unknown, prevState: State) {
    if (prevState.query !== this.state.query) {
      this.props.queryUsers(this.state.query).then((results: UsersCatalogSearchResults[]) =>
        this.setState({
          results
        })
      );
    }
  }

  changeOwner = (): void => {
    Api.chownAgent(this.props.agent, this.state.newOwner[0].id).then((agent: DsmapiResource<Agent>) => {
      this.props.hideModal();
      showSuccessToastNow(t('chown_success'));
      this.props.agentChanged(agent.resource);
    }).catch((e: any) => {
      this.props.hideModal();
      showErrorToastNow(t('chown_error'));
    });
  };

  setNewOwner = (r?: UsersCatalogSearchResults) => {
    if (r) {
      this.setState({
        newOwner: [r.user || r.team!],
        query: ''
      });
    }
  };

  resetOwner = () => {
    this.setState({
      newOwner: []
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      query: e.target.value
    });
  };

  render() {
    const { hideModal } = this.props;

    return (
      <Modal className="chown-modal" onDismiss={hideModal}>
        <ModalHeader title={t('title')} onDismiss={hideModal}>
          {t('description')}
        </ModalHeader>
        <ModalContent>
          <h3>{t('owner')}</h3>
          <UserDetails user={toUserDetailsUser(this.state.owner)} className="user-details" />
          <h3>{t('new_owner')}</h3>
          <UserSearch
            maxSelectedUsers={1}
            removeFromSelectedUsers={this.resetOwner}
            userSearchQueryChanged={this.handleChange}
            results={this.state.results.filter((res) => !userIsOwner(this.state.owner, res))}
            currentQuery={this.state.query}
            selectedUsers={this.state.newOwner}
            addToSelectedUsers={this.setNewOwner}
          />
        </ModalContent>
        <ModalFooter>
          <button className="btn btn-default" onClick={hideModal}>
            {t('cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={this.state.newOwner.length < 1}
            onClick={this.changeOwner}
          >
            {t('transfer')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}


interface ExternalProps {
  agent: Agent;
  queryUsers: (q: string) => Promise<UsersCatalogSearchResults[]>;
  hideModal: () => void;
}
interface DispatchProps {
  agentChanged: (agent: Agent) => void;
}
type Props = ExternalProps & DispatchProps;

const mapStateToProps = (state: AppState, props: ExternalProps): ExternalProps => {
  return props;
};
const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    agentChanged: (agent: Agent) => {
      dispatch({ type: ActionTypes.AgentChange, changed: agent });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChownModal);
