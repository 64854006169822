import { Checkbox } from 'common/components/Forms';
import Pager from 'common/components/Pager';
import { getTimezone } from 'common/dates';
import { fetchTranslation } from 'common/locale';
import { PhxChannel, PhxSocket } from 'common/types/dsmapi';
import { Agent } from 'common/types/gateway';
import { AppState } from 'dataGateway/store';
import { isUndefined, maxBy } from 'lodash';
import momentTimezone from 'moment-timezone';
import React, { useState } from 'react';
import DateRangePicker from 'common/components/DateRangePicker';
import { connect } from 'react-redux';
import { none, Option, option, some } from 'ts-option';
import * as dsmapiLinks from 'common/dsmapiLinks';

const PAGE_SIZE = 100;
const scope = 'data_gateway';
const t = (k: string) => fetchTranslation(k, scope );
const USELESS_ERROR = t('unknown_log_error');


interface SiaLogLine {
  date: string; // iso
  level: 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
  logger: string; // "com.socrata.sia.agent.Brain",
  message: string,
  offset: number,
  receipt_date: string; // iso
}

export function LogLine({ line, localTime }: { line: SiaLogLine, localTime: boolean }) {
  const datetime = momentTimezone(line.date, momentTimezone.ISO_8601);
  let formattedDate;
  if (localTime) {
    formattedDate = datetime.tz(getTimezone()).toISOString(true);
  } else {
    formattedDate = datetime.toISOString();
  }

  return (
    <p className="log-line">
      <span className={`log-level level-${line.level.toLowerCase()}`}>[{line.level}]</span>
      <span className="log-time">{formattedDate}</span>
      <span>{line.logger}</span>
      <span>{line.message}</span>
    </p>
  );
}

function RangeDownloader({ agent }: { agent: Agent }) {
  const [showingRange, toggleRange] = useState(false);
  const [range, setRange] = useState({ start: undefined, end: undefined });

  const onDownload = () => {
    window.open(dsmapiLinks.agentLogRange(agent, `${range.start!}Z`, `${range.end!}Z`));
  };

  if (showingRange) {
    return (
      <div className="range-downloader">
        <DateRangePicker value={range} onChange={setRange} />
        <button
          className="btn btn-primary btn-small"
          disabled={isUndefined(range.end) || isUndefined(range.start)}
          onClick={onDownload}>
          {t('start_download')}
        </button>
      </div>
    );
  } else {
    return (
      <button onClick={() => toggleRange(true)} className="btn btn-default btn-small">
        {t('download_logs')}
      </button>
    );
  }
}

const lastPage = (resultCount: Option<number>) => (
  resultCount.map(rc => Math.floor(rc / PAGE_SIZE) + 1) // page in the state is 1 based pages, api is 0 based.
);

interface State {
  chan: Option<PhxChannel>;
  loading: boolean;
  resultCount: Option<number>;
  localTime: boolean;
  page: Option<number>;
  buf: SiaLogLine[];
  follow: boolean;
  error: Option<string>;
}
export class AgentLogs extends React.Component<Props, State> {
  state: State = {
    chan: none,
    resultCount: none,
    localTime: false,
    loading: false,
    page: none,
    buf: [],
    follow: true,
    error: none
  };

  bottom = React.createRef<HTMLDivElement>();

  componentDidMount = () => {
    const agent = this.props.agent;
    const chan = this.props.socket.channel(`agent_logs:${agent.agent_uid}`);
    this.startLoading();
    chan.join()
      .receive('ok', () => {
        this.doneLoading();
        this.setState({ chan: some(chan) });
        this.tail(chan, PAGE_SIZE);
      })
      .receive('error', this.showError(USELESS_ERROR));

    chan.on('log', (line: SiaLogLine) => {
      if (this.state.follow) {
        let buf = [...this.state.buf, line];
        if (buf.length > PAGE_SIZE) {
          buf = buf.slice(1);
        }

        this.setState({ buf });
        this.scrollToBottom();
      }
      // events are always emitted in order. the last line emitted
      // is the new max.
      this.setState({ resultCount: some(line.offset) });
    });
  };

  componentWillUnmount = () => {
    this.state.chan.map(chan => {
      this.setState({ chan: none });
      chan.leave();
    });
  };

  startLoading = (): void => this.setState({ loading: true, error: none });
  doneLoading = (): void => this.setState({ loading: false });

  tail = (chan: PhxChannel, count: number) => {
    this.startLoading();
    chan.push('tail', {count: PAGE_SIZE})
      .receive('ok', ({ lines }: { lines: SiaLogLine[] }) => {
        this.doneLoading();
        const resultCount = option(maxBy(lines, l => l.offset)).map(m => m.offset);
        this.setState({ buf: lines, resultCount, page: lastPage(resultCount) });
        this.scrollToBottom();
      })
      .receive('error', this.showError(USELESS_ERROR));
  };

  range = (chan: PhxChannel, page: number): void => {
    const max = page * PAGE_SIZE;
    const min = max - PAGE_SIZE;
    this.startLoading();
    chan.push('range', { min, max })
      .receive('ok', ({ lines }: { lines: SiaLogLine[] }) => {
        this.doneLoading();
        this.setState({ buf: lines, page: some(page)});
        // if we change to the last page, let's turn on -f
        lastPage(this.state.resultCount).map(last => {
          this.setState({ follow: page === last });
        });
      })
      .receive('error', this.showError(USELESS_ERROR));
  };

  showError = (message: string) => () => {
    this.doneLoading();
    this.setState({
      error: some(message)
    });
  };

  scrollToBottom = (): void => {
    if (this.bottom) {
      this.bottom.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  changePage = (page: number): void => {
    this.state.chan.map(chan => {
      this.range(chan, page);
    });
  };

  pageInfo = (): Option<{ resultCount: number, page: number }>  => {
    return this.state.resultCount.flatMap(resultCount => (
      this.state.page.map(page => ({ resultCount, page }))
    ));
  };

  setFollow = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ follow: e.target.checked });
  };

  setLocalTime = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ localTime: e.target.checked });
  };

  isOnLastPage = (): boolean => (
    this.state.page.flatMap(page => (
      lastPage(this.state.resultCount).map(last => page === last)
    )).getOrElseValue(true)
  );

  render() {
    const logLines = (
      <div className="log-lines">
        {
          this.state.buf.length === 0 ?
            <span>{t('no_logs')}</span> :
            this.state.buf.map(ll => <LogLine key={ll.offset} line={ll} localTime={this.state.localTime} />)
        }
        <div ref={this.bottom} />
      </div>
    );

    const body = this.state.error.map(e => (
      <div className="alert error">
        {e}
      </div>
    )).getOrElseValue(
      logLines
    );

    return (
      <div className="agent-logs">
        {body}
        <div className="agent-log-navigator">
          <div>
            {
              this.isOnLastPage() ?
                (
                  <div className="navigator-option">
                    <Checkbox
                      id="follow" // id is required, or else the common component mixes up the change handlers
                      label={t('follow_logs')}
                      disabled={!this.isOnLastPage()}
                      checked={this.state.follow}
                      onChange={this.setFollow} />
                  </div>
                ) :
                null
            }
            <div className="navigator-option">
              <Checkbox
                id="time"
                label={t('local_time')}
                checked={this.state.localTime}
                onChange={this.setLocalTime} />
            </div>
            <div className="navigator-option">
              <RangeDownloader agent={this.props.agent} />
            </div>
          </div>
          <div>
            {
              this.state.loading ?
                <span className="spinner-default"></span> :
                null
            }

            {
              this.pageInfo().map(({ resultCount, page }) => (
                <Pager
                  changePage={this.changePage}
                  resultCount={resultCount}
                  resultsPerPage={PAGE_SIZE}
                  currentPage={page}
                />
              )).getOrElseValue(<span></span>)
            }
          </div>

        </div>
      </div>
    );
  }
}

interface ExternalProps {
  agent: Agent;
  socket: PhxSocket;
}
interface StateProps {
  agent: Agent;
  socket: PhxSocket;
}
type Props = StateProps;

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    socket: props.socket,
    agent: props.agent,
  };
};
export default connect(mapStateToProps)(AgentLogs);


