import AvailablePluginsModal from 'common/components/AvailablePluginsModal';
import Stepper from 'common/components/hocs/withStepper/Stepper';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import { finishOptions, steps, title } from 'common/components/ProvisionAgentModal';
import ProvisionPluginModal from 'common/components/ProvisionPluginModal';
import { Agent, Plugin } from 'common/types/gateway';
import React from 'react';
import { none, Option, some } from 'ts-option';
import * as Api from '../api';
import ChownModal from '../components/ChownModal';
import DeleteAgentModal from '../components/DeleteAgentModal';

function ProvisionAgentModal({ hideModal, showAvailablePlugins }: { hideModal: () => void, showAvailablePlugins: () => void }) {
  const finishAction = finishOptions(showAvailablePlugins, hideModal);
  return (
    <PendoIdWrapper id="provision-plugin-modal" displayStyle={DisplayStyle.BLOCK}>
      <Stepper
        steps={steps}
        onDismiss={hideModal}
        modalTitle={title}
        showSteps={true}
        selectedFinish={finishAction[0]}
        finishOptions={finishAction} />
    </PendoIdWrapper>
  );
}

export enum AgentModalType {
  ProvisionAgentModalT,
  ProvisionPluginModalT,
  AvailablePluginsModalT,
  DeleteAgentModalT,
  ChownModalT
}

interface ProvisionAgent { type: AgentModalType.ProvisionAgentModalT }
interface ProvisionPlugin { type: AgentModalType.ProvisionPluginModalT, plugin: Plugin }
interface AvailablePlugins { type: AgentModalType.AvailablePluginsModalT }
interface DeleteAgent { type: AgentModalType.DeleteAgentModalT, agent: Agent }
interface ChownAgent { type: AgentModalType.ChownModalT, agent: Agent }



export type AgentModal =
  ProvisionAgent |
  ProvisionPlugin |
  AvailablePlugins |
  DeleteAgent |
  ChownAgent;


export interface WithModalProps {
  showModal: (modal: AgentModal) => void;
  hideModal: () => void;
}

interface ModalState {
  modal: Option<AgentModal>
}

interface GatewayLikeProps {
  plugins: Plugin[],
  agents: Agent[]
}


function withModal<T extends GatewayLikeProps>(component: React.ComponentClass<T & WithModalProps>, klasses: string[] = []) {
  return class WithModal extends React.Component<T, ModalState> {
    state = {
      modal: none
    };

    showModal = (m: AgentModal) => this.setState({ modal: some(m )});
    hideModal = () => this.setState({ modal: none });
    showProvisionPluginModal = (plugin: Plugin) => {
      this.setState({ modal: some({ type: AgentModalType.ProvisionPluginModalT, plugin })});
    };

    showAvailablePlugins = () => {
      this.setState({ modal: some({ type: AgentModalType.AvailablePluginsModalT }) });
    };

    render() {
      const theModal = this.state.modal.map((modal: AgentModal) => {
        switch (modal.type) {
          case AgentModalType.ChownModalT:
            return (
              <ChownModal
                agent={modal.agent}
                queryUsers={Api.queryUsers}
                hideModal={this.hideModal}
              />
            );
          case AgentModalType.ProvisionAgentModalT:
            return (
              <PendoIdWrapper id="provision-agent-modal" displayStyle={DisplayStyle.BLOCK}>
                <ProvisionAgentModal hideModal={this.hideModal} showAvailablePlugins={this.showAvailablePlugins}  />
              </PendoIdWrapper>
            );
          case AgentModalType.ProvisionPluginModalT:
            return (
              <ProvisionPluginModal plugin={modal.plugin} hideModal={this.hideModal} />
            );
          case AgentModalType.AvailablePluginsModalT:
            return (
              <AvailablePluginsModal
                plugins={this.props.plugins}
                hideModal={this.hideModal}
                showPluginHelp={this.showProvisionPluginModal}
                canSetupPlugins={this.props.agents.length > 0} />
            );
          case AgentModalType.DeleteAgentModalT:
            return (
              <DeleteAgentModal
                hideModal={this.hideModal}
                agent={modal.agent} />
            );
          default:
            return none;
        }
      }).getOrElseValue(<div></div>);

      const props = {
        ...this.props,
        hideModal: this.hideModal,
        showModal: this.showModal
      };

      return (
        <div className={`agent-modal-wrapper ${klasses.join(' ')}`}>
          {theModal}
          {React.createElement(component, props, null)}
        </div>
      );
    }
  };
}

export default withModal;
