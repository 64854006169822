import AccessManager from 'common/components/AccessManager';
import { MODES } from 'common/components/AccessManager/Constants';
import { savePermissions } from 'common/components/AccessManager/Util';
import Dropdown from 'common/components/Dropdown';
import GatewayOnlineOffline from 'common/components/GatewayOnlineOffline';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import SortFilterResultsTable, { Column } from 'common/components/SortFilterResultsTable';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { getView } from 'common/core/views_service';
import I18n from 'common/i18n';
import { fetchTranslation } from 'common/locale';
import { DsmapiResource } from 'common/types/dsmapi';
import { Agent, Namespace, Plugin } from 'common/types/gateway';
import { View } from 'common/types/view';
import { ActionTypes, AppState, Dispatcher } from 'dataGateway/store';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { none, Option } from 'ts-option';
import * as Api from '../api';
import { canUpdateAgent } from '../auth';
import withModal, { AgentModalType, WithModalProps } from '../hocs/withModal';
import Connecting from './Connecting';
import Dashboard from './Dashboard';

const indexRoute = () => '/admin/gateway';
const detailsRoute = (agent: Agent) => `${indexRoute()}/${agent.agent_uid.toString()}`;


const scope = 'data_gateway';
const t = (k: string) => fetchTranslation(k, scope);

type AgentTableSortKey =
  'AGENT_NAME' |
  'PLUGIN_COUNT' |
  'AGENT_ID' |
  'AGENT_VERSION' |
  'AGENT_OWNER' |
  'AGENT_STATUS';

const mapSortKeyToValue = (k: AgentTableSortKey) => (a: Agent) => {
  switch (k) {
    case 'AGENT_NAME':
      return a.name;
    case 'PLUGIN_COUNT':
      return a.namespaces.length;
    case 'AGENT_ID':
      return a.agent_uid.toString();
    case 'AGENT_VERSION':
      return _.get(a, 'version.version', '');
    case 'AGENT_OWNER':
      return a.owned_by.email;
    case 'AGENT_STATUS': {
      if (a.went_offline_at) {
        return -1;
      } else if (a.went_online_at) {
        return 1;
      } else {
        return 0;
      }
    }
  }
};

const searchPredicate = (term: string, agent: Agent) => {
  return _.includes(agent.name.toLowerCase(), term.toLowerCase());
};


export class AgentsTable extends React.Component<Props, State> {
  detailsRefs: {
    [auid: string]: any;
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      agent: none,
      plugins: [],
      plugin: none,
      completedAgentSetup: false,
      connected: false,
    };
    this.detailsRefs = {};

    this.openAccessManager = this.openAccessManager.bind(this);
    this.closeAccessManager = this.closeAccessManager.bind(this);
    this.onAccessManagerConfirm = this.onAccessManagerConfirm.bind(this);
  }


  closeAccessManager() {
    this.setState({
      selectedNamespace: undefined,
      selectedNamespaceView: undefined
    });
  }

  async onAccessManagerConfirm(mode: string, assetUid: string, permissions: any) {
    await savePermissions(assetUid, permissions);
    this.closeAccessManager();
    showSuccessToastNow(I18n.t('access_manager.access_updated', { scope }));
  }

  async openAccessManager(selectedNamespace: Namespace) {
    try {
      const view = await getView(selectedNamespace.lens_uid);
      const selectedNamespaceView = Object.assign(view, { namespace: selectedNamespace });
      this.setState({ selectedNamespace, selectedNamespaceView });
    } catch (err) {
      showErrorToastNow(t('load_view_error'));
    }
  }

  renderAccessManager(): JSX.Element | undefined {
    const { selectedNamespace, selectedNamespaceView } = this.state;
    if (selectedNamespace) {
      const accessManagerProps = {
        view: selectedNamespaceView!,
        mode: MODES.MANAGE_PLUGIN,
        onDismiss: this.closeAccessManager,
        onConfirm: this.onAccessManagerConfirm
      };
      return <AccessManager {...accessManagerProps} />;
    }
  }

  render() {
    const { agents, connected } = this.props;
    if (!connected) {
      return <Connecting />;
    }

    return (
      <div className="agents-table-dashboard">
        <div>
          <Dashboard />
          <div>
            <SortFilterResultsTable
              items={agents}
              searchPlaceholder={t('search')}
              searchLabel={t('search')}
              searchPredicate={searchPredicate}
              rowKey={'agent_uid'}
              mapSortKeyToValue={mapSortKeyToValue}
              rowClassFunction={() => 'connection_agent'}
              id={'connection-agents'}
              noResultsMessage={t('no_agents')}
              defaultSort={{
                by: 'AGENT_NAME',
                direction: 'ASC'
              }}
            >
              <Column<Agent, AgentTableSortKey>
                header={t('agent_name')}
                sortKey={'AGENT_NAME'}
                render={(agent) => (
                    <Link to={detailsRoute(agent)}>
                      {agent.name}
                    </Link>
                  )}
              />
              <Column<Agent, AgentTableSortKey>
                header={t('plugins')}
                sortKey={'PLUGIN_COUNT'}
                render={(agent) => {
                  const text = `${agent.namespaces.length} ${t(agent.namespaces.length === 1 ? 'plugins_single' : 'plugins_plural')}`;
                  return (
                    <div ref={(ref: any) => this.detailsRefs[agent.agent_uid.toString()] = ref}>
                      {text}
                    </div>
                  );
                }}
              />
              <Column<Agent, AgentTableSortKey>
                header={t('id')}
                sortKey={'AGENT_ID'}
                render={(agent) => agent.agent_uid.toString()}
              />
              <Column<Agent, AgentTableSortKey>
                header={t('version')}
                sortKey={'AGENT_VERSION'}
                render={(agent) => agent.version ? agent.version.version : null}
              />
              <Column<Agent, AgentTableSortKey>
                header={t('owned_by')}
                sortKey={'AGENT_OWNER'}
                render={(agent) => agent.owned_by.email}
              />
              <Column<Agent, AgentTableSortKey>
                header={t('status')}
                sortKey={'AGENT_STATUS'}
                render={(agent) => {
                  return (<GatewayOnlineOffline agent={agent} />);
                }}
              />
              <Column<Agent, never>
                header={t('actions')}
                render={(agent) => {
                  const disabled = !canUpdateAgent(_.get(window, 'socrata.currentUser'), agent);
                  return (<Dropdown
                    placeholder={() => (
                      <button className="btn btn-default">
                        <SocrataIcon name={IconName.Kebab} />
                      </button>
                    )}
                    picklistSizingStrategy={'EXPAND_TO_WIDEST_ITEM'}
                    onSelection={(option: any) => option.value()}
                    options={[
                      { title: t('delete'), value: () => {
                        this.props.showModal({ type: AgentModalType.DeleteAgentModalT, agent });
                      }, disabled },
                      { title: 'Transfer Ownership', value: () => {
                        this.props.showModal({ type: AgentModalType.ChownModalT, agent });
                      }, disabled }
                    ]}
                  />);
                }}
              />

            </SortFilterResultsTable>
          </div>
        </div>
        {this.renderAccessManager()}
      </div>
    );
  }
}

interface ExternalProps {}
interface DispatchProps {
  chownAgent: (agent: Agent, userId: string) => Promise<DsmapiResource<Agent>>;
}
interface StateProps {
  agents: Agent[];
  plugins: Plugin[];
  connected: boolean;
}


type Props = WithModalProps & DispatchProps  & StateProps;
interface State {
  agent: Option<Agent>;
  plugins: Plugin[];
  plugin: Option<Plugin>;
  completedAgentSetup: boolean;
  connected: boolean;
  selectedNamespace?: Namespace;
  selectedNamespaceView?: View;
}


const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    agents: state.agents,
    plugins: state.plugins,
    connected: state.connected
  };
};
const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {

    chownAgent: (agent: Agent, userId: string) => {
      return Api.chownAgent(agent, userId).then(a => {
        dispatch({ type: ActionTypes.AgentChange, changed: a.resource });
        return a;
      });
    }
  };
};

// kind of gnarly. but withModal wants agenty props passed in, so we need to do it
// after the connect. TS will enforce this properly at least.
export default connect(mapStateToProps, mapDispatchToProps)(withModal(AgentsTable));
