import _ from 'lodash';
import { fetchTranslation } from 'common/locale';
import { appToken, csrfToken } from 'common/http';

const scope = 'dataset_management_ui.validation_failures';
const fallback = fetchTranslation('generic', scope);
const t = (k) => fetchTranslation(k, scope, fallback);

const defaultFetchOptions = {
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-App-Token': appToken()
  }
};

const headersForWrites = {
  'X-CSRF-Token': csrfToken()
};

export function socrataFetch(path, options = {}) {
  // only need to add in authenticityToken for non-GET requests
  const mergedBasic = _.merge(options, defaultFetchOptions);
  const mergedForWrites = (!_.isUndefined(options.method) && options.method.toUpperCase() !== 'GET') ?
    _.merge(mergedBasic, { headers: headersForWrites }) :
    mergedBasic;
  return fetch(path, mergedForWrites);
}

// Used to throw errors from non-200 responses when using fetch.
export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}


export function getJson(resp) {
  if (resp) {
    return resp.json();
  } else {
    return resp;
  }
}

function renderValidationFailure(body) {
  if (body &&
    body.key === 'validation_failed' &&
    body.message &&
    body.params &&
    body.params.invalid_keys &&
    _.isArray(body.params.invalid_keys)
  ) {
    return body.params.invalid_keys.reduce((acc, key) => {
      let tmp = acc.replace(`{${key}}`, t(key));
      return tmp.replace(`{${key.replace(' ', '_')}}`, t(key));
    }, body.message);
  }
}

export function getError(err) {
  return err.response.json().then((body) => {
    err.body = body;
    if (err.response.status === 400) {
      const message = renderValidationFailure(body);
      if (message) {
        err.message = message;
      }
    }

    throw err;
  });
}
