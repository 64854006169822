import AccessManager from 'common/components/AccessManager';
import { MODES } from 'common/components/AccessManager/Constants';
import { savePermissions } from 'common/components/AccessManager/Util';
import PluginIcon from 'common/components/PluginIcon';
import SortFilterResultsTable, { Column } from 'common/components/SortFilterResultsTable';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { getView } from 'common/core/views_service';
import I18n from 'common/i18n';
import { fetchTranslation } from 'common/locale';
import { Agent, Namespace, Plugin } from 'common/types/gateway';
import { View } from 'common/types/view';
import { AppState } from 'dataGateway/store';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { none, Option, some } from 'ts-option';

const scope = 'data_gateway';
const t = (k: string) => fetchTranslation(k, scope );

type NamespaceSortKey =
  'NAME' |
  'TYPE';

const searchPredicate = (term: string, ns: Namespace) => {
  return (
    _.includes(ns.name.toLowerCase(), term.toLowerCase()) ||
    _.includes(ns.type.toLowerCase(), term.toLowerCase())
  );
};

const mapSortKeyToValue = (k: NamespaceSortKey) => (ns: Namespace) => {
  switch (k) {
    case 'NAME':
      return ns.name;
    case 'TYPE':
      return ns.type;
  }
};


export class NamespacesTable extends React.Component<Props, State> {
  state: State = {
    selected: none
  };

  lookupPlugin(namespaceType: string): Plugin | undefined {
    return this.props.plugins.find(p => p.type === namespaceType);
  }

  onPermissionsClick = (selectedNamespace: Namespace) => {
    this.openAccessManager(selectedNamespace);
  };


  closeAccessManager = () => {
    this.setState({
      selected: none
    });
  };

  async onAccessManagerConfirm(mode: string, assetUid: string, permissions: any) {
    await savePermissions(assetUid, permissions);
    this.closeAccessManager();
    showSuccessToastNow(I18n.t('access_manager.access_updated', { scope }));
  }

  async openAccessManager(namespace: Namespace) {
    try {
      const view: View = await getView(namespace.lens_uid);
      this.setState({ selected: some({ namespace, view })});
    } catch (err) {
      showErrorToastNow(t('load_view_error'));
    }
  }

  renderAccessManager(): JSX.Element | null {
    return this.state.selected.map<JSX.Element | null>(({ namespace, view }) => {
      const accessManagerProps = {
        view: { ...view, namespace}!,
        mode: MODES.MANAGE_PLUGIN,
        onDismiss: this.closeAccessManager,
        onConfirm: async (mode: string, assetUid: string, permissions: any) => {
          await this.onAccessManagerConfirm(mode, assetUid, permissions);
          this.closeAccessManager();
        }
      };
      return <AccessManager {...accessManagerProps} />;
    }).getOrElseValue(null);
  }



  render() {
    const { agent } = this.props;
    return (
      <div className="plugins-table">
        {this.renderAccessManager()}
        <div className="namespaces-list">
          <SortFilterResultsTable
            embedded={true}
            items={agent.namespaces}
            searchPlaceholder={t('search_plugins')}
            searchPredicate={searchPredicate}
            rowKey={'name'}
            mapSortKeyToValue={mapSortKeyToValue}
            rowClassFunction={() => 'namespace'}
            id={'namespaces'}
            noResultsMessage={t('no_plugins')}
            defaultSort={{
              by: 'NAME',
              direction: 'ASC'
            }}
          >
            <Column<Namespace, NamespaceSortKey>
              header={t('type')}
              sortKey={'TYPE'}
              render={(ns) => {
                const plugin = this.lookupPlugin(ns.type);
                const icon = plugin ? <PluginIcon plugin={plugin} /> : null;
                return (
                  <div className="namespace-cell namespace-type-with-icon">
                    {icon}
                    {ns.type}
                  </div>
                );
              }}
            />
            <Column<Namespace, NamespaceSortKey>
              header={t('plugin_name')}
              sortKey={'NAME'}
              render={(ns) => (
                ns.name
              )}
            />
            <Column<Namespace, never>
              header={t('manage_permissions')}
              render={(ns) => (
                <button className="btn btn-default btn-small" onClick={() => this.onPermissionsClick(ns)}>{t('manage_permissions')}</button>
              )}
            />
          </SortFilterResultsTable>
        </div>
      </div>
    );
  }
}

interface ExternalProps {
  agent: Agent;
}
interface DispatchProps {
}
interface StateProps {
  agent: Agent;
  plugins: Plugin[]
}

type Props =  DispatchProps & StateProps;
interface State {
  selected: Option<{ namespace: Namespace, view: View }>
}

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    agent: props.agent,
    plugins: state.plugins
  };
};

export default connect(mapStateToProps)(NamespacesTable);


