import { AppState } from 'adminRoles/appStates';
import Pager from 'common/components/Pager';
import ResultsTable from 'common/components/ResultsTable';
import { formatDateWithLocale } from 'common/dates';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import I18n from 'common/i18n';
import { composeQuery, f, select } from 'common/soql_builder';
import { OrderByDsl } from 'common/soql_builder/orderBy';
import { QueryParts } from 'common/soql_builder/query/build';
import { isViewColumn } from 'common/soql_builder/util';
import { formatDsmapiDateWithLocale } from 'common/types/dsmapi';
import { Agent } from 'common/types/gateway';
import { SoQLType } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { isNaN, snakeCase } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { none, Option, option, some } from 'ts-option';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'data_gateway', ...options });

interface Activity<T> {
  id: string;
  created_at: string;
  activity_type: string;
  details: T;
}

interface PluginDetails {
  plugin_type: string;
  plugin_name: string;
}
interface OnlineDetails {}
interface OfflineDetails { reason: string, offlineAt: string }
interface CreatedDetails {}
interface DeletedDetails {}

type ParsedDetails =
  PluginDetails |
  OnlineDetails |
  OfflineDetails |
  CreatedDetails |
  DeletedDetails;

const isPluginDeletionEvent = (a: Activity<ParsedDetails>): a is Activity<PluginDetails> => a.activity_type === 'PluginDeleted';
const isPluginCreationEvent = (a: Activity<ParsedDetails>): a is Activity<PluginDetails> => a.activity_type === 'PluginConfigured';
const isOnlineEvent = (a: Activity<ParsedDetails>): a is Activity<OnlineDetails> => a.activity_type === 'AgentOnline';
const isOfflineEvent = (a: Activity<ParsedDetails>): a is Activity<OfflineDetails> => a.activity_type === 'AgentOffline';
const isAgentCreatedEvent = (a: Activity<ParsedDetails>): a is Activity<CreatedDetails> => a.activity_type === 'AgentCreated';
const isAgentDeletedEvent = (a: Activity<ParsedDetails>): a is Activity<DeletedDetails> => a.activity_type === 'AgentDeleted';

type SortDirection = 'DESC' | 'ASC';
const PAGE_SIZE = 10;
interface PageInfo { resultCount: number; page: number }

export const column = (fieldName: string) => ({ fieldName, dataTypeName: 'text' } as ViewColumn);

const initialQuery = (agentUid: string): QueryParts => ({
  selects: [
    select(column('id'), 'id'),
    select(column('created_at'), 'created_at'),
    select(column('activity_type'), 'activity_type'),
    select(column('details'), 'details'),
  ],
  where: f.eq(
    f.cast(SoQLType.SoQLTextT)(f.subscript(f.cast(SoQLType.SoQLJsonT)(column('details')), 'agent_uid')),
    `"${agentUid}"`
  ),
  orders: [
    { descending: true, nullLast: true, expr: column('created_at')}
  ],
  limit: PAGE_SIZE,
  offset: 0
});

const SmallSpinner = () => <p className="spinner-default"></p>;

function AgentActivityDetails({ activity }: { activity: Activity<ParsedDetails> }) {
  let body = '';
  if (isPluginCreationEvent(activity)) {
    body = t('plugin_created', activity.details);
  } else if (isPluginDeletionEvent(activity)) {
    body = t('plugin_deleted', activity.details);
  } else if (isOfflineEvent(activity)) {
    body = t('agent_offline', activity.details);
  } else {
    body = t(snakeCase(activity.activity_type));
  }

  return <span>{body}</span>;
}


interface State {
  activities: Option<Activity<ParsedDetails>[]>;
  error: Option<string>;
  resultCount: Option<number>;
  query: Option<QueryParts>;
}
export class AgentEvents extends React.Component<Props, State> {
  state: State = {
    activities: none,
    error: none,
    query: none,
    resultCount: none
  };

  componentDidMount = () => {
    this.updateQuery(some(initialQuery(this.props.agent.agent_uid)));
    this.updateCount();
  };

  updateQuery = (query: Option<QueryParts>) => {
    this.setState({ query, activities: none });
    query.forEach(this.sendQuery);
  };

  updateCount = async () => {
    try {
      const soql = composeQuery({
        ...initialQuery(this.props.agent.agent_uid),
        selects: [select(f.count(column('id')), 'id')],
        orders: [],
        limit: undefined,
        offset: undefined
      });
      const res: {id: string}[] = await fetchJsonWithDefaultHeaders(`/api/activity_log?$query=${soql}`, { method: 'GET'});
      const [count] = res.map((row) => parseInt(row.id)).filter(num => !isNaN(num));
      this.setState({ resultCount: option(count) });
    } catch (e) {
      console.error(e);
    }
  };

  sendQuery = async (query: QueryParts) => {
    try {
      const soql = composeQuery(query);
      const res: Activity<string>[] = await fetchJsonWithDefaultHeaders(`/api/activity_log?$query=${soql}`, { method: 'GET'});
      this.setState({ activities: some(res.map(activity => ({...activity, details: JSON.parse(activity.details)}))) });
    } catch (e) {
      console.error(e);
      this.setState({ error: some(t('unknown_error')) });
    }
  };

  onSort = (fieldName: string) => () => {
    this.updateQuery(this.state.query.map(query => {
      const newOrder = this.getSortFor(fieldName).map(order => ({...order, descending: !order.descending})).getOrElseValue({
        descending: false,
        expr: column(fieldName),
        nullLast: true
      });

      // activity-feed UI only allows one order at a time. that kind of simplifies things :shrug:
      return {
        ...query,
        orders: [newOrder]
      };
    }));
  };

  getSortFor = (fieldName: string): Option<OrderByDsl> => (
    this.state.query.flatMap(query => (
      option((query.orders || []).find((order) => (
        order && isViewColumn(order.expr) && order.expr.fieldName === fieldName
      )))
    ))
  );

  getSortDirection = (fieldName: string): Option<SortDirection> => (
    this.getSortFor(fieldName).map(order => order.descending ? 'DESC' : 'ASC')
  );

  getPageInfo = (): Option<PageInfo> => (
    this.state.query.flatMap(query => {
      const page = Math.floor((query.offset || 0) / PAGE_SIZE) + 1;
      return this.state.resultCount.map(resultCount => ({ resultCount, page }));
    })
  );

  changePage = (page: number) => {
    this.updateQuery(this.state.query.map(query => ({
      ...query, offset: (Math.max(page - 1, 0) * PAGE_SIZE)
    })));
  };

  renderError = () => (
    this.state.error.map(reason => <div className="alert error">{reason}</div>)
  );

  renderActivities = () => (
    <div className="agent-events-table">
      <ResultsTable
        data={this.state.activities.getOrElseValue([])}
        rowKey={'id'}
        loadingData={this.state.activities.isEmpty}
        noResultsMessage={t('no_activities')}
        rowClassFunction={() => 'activity'}
        id={'agent-activities'} >

        {[
          <ResultsTable.Column
            key="created-at"
            dataIndex={'created_at'}
            header={t('event_time')}
            sortDirection={this.getSortDirection('created_at').getOrElseValue(null as any)}
            isActive={this.getSortFor('created_at').isDefined}
            onSort={this.onSort('created_at')}>
              {(unused: never, activity: Activity<ParsedDetails>) => {
                if (isOfflineEvent(activity)) {
                  // So dsmapi will supress the offline event for a few minutes, waiting for the
                  // agent to come back online. If the agent doesn't come back online, then it emits
                  // the event. So the event time is always a few minutes later than when the agent
                  // actual went away. So for offline events we will use the timestamp in the
                  // details, rather than the event time.
                  return formatDsmapiDateWithLocale(activity.details.offlineAt);
                }
                return formatDateWithLocale(activity.created_at, true);
              }}
          </ResultsTable.Column>,
          <ResultsTable.Column
            key="activity-type"
            dataIndex={'activity_type'}
            header={t('activity_type')}
            sortDirection={this.getSortDirection('activity_type').getOrElseValue(null as any)}
            isActive={this.getSortFor('activity_type').isDefined}
            onSort={this.onSort('activity_type')}>
              {(unused: never, activity: Activity<ParsedDetails>) => activity.activity_type}
          </ResultsTable.Column>,
          <ResultsTable.Column
            key="details"
            dataIndex={'details'}
            header={t('details')}
            isActive={false}
            onSort={() => {}}>
              {(unused: never, activity: Activity<ParsedDetails>) => <AgentActivityDetails activity={activity} />}
          </ResultsTable.Column>

        ]}
      </ResultsTable>
      {
        this.getPageInfo().map(({page, resultCount}) => (
          <Pager
            changePage={this.changePage}
            resultCount={resultCount}
            resultsPerPage={PAGE_SIZE}
            currentPage={page} />
        )).getOrElseValue(<SmallSpinner />)
      }
    </div>
  );

  render = () => (
    <div className="agent-events">
      {this.renderError().getOrElse(this.renderActivities)}
    </div>
  );
}

interface ExternalProps {
  agent: Agent;
}
interface StateProps {
  agent: Agent;
}
type Props = StateProps;

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    agent: props.agent,
  };
};
export default connect(mapStateToProps)(AgentEvents);


